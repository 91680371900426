@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;800&display=swap%22");
.partner-card {
  margin-top: -8%;
  transition: 0.3s;
  width: 424px;
  height: 220px;
  background: linear-gradient(
    282.25deg,
    rgba(31, 40, 53, 0) 0%,
    rgba(31, 40, 53, 0.8) 100%
  );
  border-radius: 16px;
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.25);
  margin-bottom: 15%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.partner-card:hover {
  transform: scale(1.05);
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.5);
  background: linear-gradient(
    282.25deg,
    rgba(2, 101, 240, 0.5) 10%,
    rgba(1, 53, 124, 0.8) 60%
  );
  cursor: pointer;
  
}

.partner-desc__title {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 32px;
  text-transform: uppercase;
  color: #1799e1;
}

.partner-desc__subtitle {
  margin-top: 5%;
  margin-left: 6%;
  margin-right: 6%;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 160.02%;
  opacity: 0.8;
}

@media screen and (max-width: 768px) {
  .partner-card {
    margin-top: -20%;
    transition: 0.3s;
    margin-left: 10%;
    margin-right: 10%;
    width: auto;
    height: 220px;
    background: linear-gradient(
      282.25deg,
      rgba(31, 40, 53, 0) 0%,
      rgba(31, 40, 53, 0.8) 100%
    );
    border-radius: 16px;
    box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.25);
    margin-bottom: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .partner-desc__title {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 37px;
    text-transform: uppercase;
    color: #1799e1;
  }

  .partner-desc__subtitle {
    margin-top: 5%;
    margin-left: 5%;
    margin-right: 5%;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 160.02%;
    opacity: 0.8;
  }
}
