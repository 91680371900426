@tailwind base;
@tailwind components;
@tailwind utilities;

/* @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;800&display=swap'); */

body {
  background-color: #081220;
}

::-webkit-scrollbar {
  width: 0.5rem;
  background: #121212;
}

/* Track */
::-webkit-scrollbar-track {
  background: #121212;
  border-radius: 0.5rem;
  z-index: -99;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #3e62df;
  border-radius: 0.5rem;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #3e62df;
  visibility: visible;
}
